(function ($) {
  Drupal.behaviors.boutiqueX5CountdownClockV1 = {
    attach: function (context) {
      var $thisModule = $('.js-boutique-x5-countdown-clock-v1', context);
      var $timerInSiteBanner = $('.js-boutique-x5-countdown-clock-site-banner', context);
      var $timer = $thisModule.find('.js-boutique-x5-countdown-clock__timer');
      var $days = $timer.find('.js-boutique-x5-countdown-clock__timer--days');
      var $hours = $timer.find('.js-boutique-x5-countdown-clock__timer--hours');
      var $minutes = $timer.find('.js-boutique-x5-countdown-clock__timer--minutes');
      var $seconds = $timer.find('.js-boutique-x5-countdown-clock__timer--seconds');
      var $countdownDate = $thisModule.find('.js-boutique-x5-countdown-clock__countdowndate');
      var countdownDate = $countdownDate.data('countdown-date');
      var timerEnabledInBanner = $timerInSiteBanner.data('enableCounterBanner');

      function setUnits(days, hours, minutes, seconds) {
        $days.html(days);
        $hours.html(hours);
        $minutes.html(minutes);
        $seconds.html(seconds);
      }

      function createTimer() {
        // Set the date we're counting down to
        var countDownDate = new Date(countdownDate).getTime();
        // Update the count down every 1 second
        var x = setInterval(function () {
          // Get today's date and time
          var now = new Date().getTime();
          // Find the distance between now and the count down date
          var distance = countDownDate - now;
          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          setUnits(days, hours, minutes, seconds);

          // If the count down is over, write some text
          if (distance < 0) {
            clearInterval(x);
            setUnits(0, 0, 0, 0);
          }
        }, 1000);
      }
      createTimer();

      if(timerEnabledInBanner) {
        $('.js-site-banner-formatter').addClass('site-banner-formatter__with-counter');
      }
    }
  };
})(jQuery);
